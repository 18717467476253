export const QuestionMarkIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.92308 7C1.92308 4.19609 4.19609 1.92308 7 1.92308C9.80391 1.92308 12.0769 4.19609 12.0769 7C12.0769 9.80391 9.80391 12.0769 7 12.0769C4.19609 12.0769 1.92308 9.80391 1.92308 7ZM7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM6.94578 8.73035C6.63624 8.73035 6.38531 8.47372 6.38531 8.15715V7.9831C6.38531 7.59189 6.73539 7.02836 7.3312 6.65167C7.7385 6.3914 8.05155 6.06265 8.05155 5.58322C8.05155 5.04557 7.63752 4.69627 7.1225 4.69627C6.78179 4.69627 6.43414 4.85404 6.26508 5.18198C6.09603 5.50992 5.80401 5.64828 5.43275 5.64828C5.06148 5.64828 4.83715 5.2258 4.9162 4.95048C5.16535 4.08275 5.94929 3.5 7.1225 3.5C8.40837 3.5 9.30039 4.40861 9.30039 5.56609C9.30039 6.35031 8.91328 6.86399 8.29392 7.24068C7.74523 7.58313 7.50624 7.85601 7.50624 8.12017V8.15715C7.50624 8.47372 7.25531 8.73035 6.94578 8.73035ZM7.71494 10.1138C7.71157 10.5316 7.37159 10.8672 6.97439 10.8672C6.56372 10.8672 6.23047 10.5316 6.23384 10.1138C6.23047 9.70291 6.56372 9.36731 6.97439 9.36731C7.37159 9.36731 7.71157 9.70291 7.71494 10.1138Z'
      />
    </svg>
  );
};
